import { Variant } from '@/interfaces/Variant.interface';

export const VariantSchema: Variant = {
	_id: '',
	name: '',
	fileName: '',
	style: '',
	tempo: '',
	production: '',
	category: ''
};
