var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pack._id)?_c('v-card',{staticClass:"mx-auto",attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"dense":""}},[_c('v-toolbar-title',[_vm._v("Pack Loops")]),_c('v-spacer'),(Object.keys(_vm.loops).length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"slot":"activator","icon":""},on:{"click":_vm.handleDeleteLoops},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,2073848485)},[_c('span',[_vm._v("Delete Loops")])]):_vm._e()],1),_c('v-card',{staticClass:"section",attrs:{"sm12":""}},[_c('v-container',[(!Object.keys(_vm.loops).length)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{staticClass:"generate-loops-input",attrs:{"label":"# of Loops","type":"number","min":"1","max":"100","required":""},model:{value:(_vm.numLoopsToGenerate),callback:function ($$v) {_vm.numLoopsToGenerate=$$v},expression:"numLoopsToGenerate"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"slot":"activator","disabled":_vm.numLoopsToGenerate < 1,"icon":""},on:{"click":_vm.generateLoops},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cloud-upload")])],1)]}}],null,false,1555076665)},[_c('span',[_vm._v("Generate Loops")])])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.getTableHeaders(),"items":_vm.getTableData(),"items-per-page":20},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"editable-column"},[_c('v-text-field',{attrs:{"value":item.name,"label":"Name","required":""},on:{"change":function (val) { return _vm.handleChange(item._id, 'name', val); }}})],1)]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"editable-column"},[_c('v-select',{attrs:{"items":_vm.values(_vm.categories),"value":item.category,"label":"Category","item-text":"name","return-object":""},on:{"change":function (val) { return _vm.handleChange(item._id, 'category', val); }}})],1)]}},{key:"item.midi",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.values(item.midi) .map(function (m) { return ((m.name) + " - " + (m.fileName)); }) .join(', '))+" ")]}},{key:"item.productions",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.values(item.productions) .map(function (m) { return m.name; }) .join(', '))+" ")]}},{key:"item.styles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.values(item.styles) .map(function (m) { return m.name; }) .join(', '))+" ")]}},{key:"item.tempos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.values(item.tempos) .map(function (m) { return m.name; }) .join(', '))+" ")]}},{key:"item.variants",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.values(item.variants) .map(function (m) { return m.name; }) .join(', '))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"slot":"activator","icon":""},on:{"click":function($event){return _vm.openMidiUploadDialog(item)}},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cloud")])],1)]}}],null,true)},[_c('span',[_vm._v("Upload Midi")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"slot":"activator","icon":""},on:{"click":function($event){return _vm.handleDeleteLoop(item._id)}},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Loop")])])]}}],null,false,885575120)})],1)],1)],1)],1),_c('confirm-dialog',{ref:"confirmDialog"}),_c('loop-midi-upload-dialog',{ref:"loopMidiUploadDialog",on:{"upload":_vm.handleLoopMidiUpload}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }