import { Loop } from '@/interfaces/Loop.interface';

export const LoopSchema: Loop = {
	_id: '',
	name: '',
	fileName: '',
	trackNumber: 0,
	category: '',
	midi: [],
	productions: [],
	styles: [],
	tempos: [],
	variants: []
};
